import * as Sentry from "@sentry/react";
import { JSONApiError } from "../network/jsonApiV2/models/core/JSONApiError";
import {
  JSONApiErrorsException,
  JSONApiErrorsPayload,
} from "../network/jsonApiV2/models/core/JSONApiErrorsPayload";

/**
 * Warning: not an ideal function, but it works for now.
 * Ideally we should be able to specify errors codec to be able to catch not only `title` property but something else
 *
 * mapping: {
 *   "zip": "data/attributes/zip",
 *   "fooBar": "data/attributes/foo_bar"
 * }
 *
 * result: {
 *   "zip": "Error text"
 * }
 */
export function mapJSONApiErrors<M extends Record<string, string>>(
  errors: JSONApiErrorsPayload["errors"],
  mapping: M,
): { [key in keyof M]?: JSONApiError | undefined } {
  const result: { [key in keyof M]?: JSONApiError | undefined } = {};

  for (const error of errors) {
    if (error.source?.pointer) {
      const resKey = findKeyInMapping(mapping, error.source.pointer);
      if (resKey) {
        result[resKey] = error;
      } else if (error.source.pointer !== "data/attributes/base") {
        Sentry.captureMessage(
          `Pointer "${error.source.pointer}" not mapped to a field`,
        );
        // eslint-disable-next-line no-console
        console.warn(
          `Pointer "${error.source.pointer}" not mapped to a field`,
        );
      }
    }
  }

  return result;
}

function findKeyInMapping<M extends Record<string, string>>(
  mapping: M,
  pointer: string,
): keyof M | undefined {
  const res = Object.entries(mapping).find(([, v]) => {
    return v === pointer;
  });
  return res ? (res[0] as keyof M) : undefined;
}

export const getBaseErrorText = (error: unknown): string | null => {
  if (error instanceof JSONApiErrorsException) {
    const baseErrors = error.errors.filter(
      (item) =>
        item.source?.pointer === "data/attributes/base" ||
        item.code === "not_found",
    );
    if (baseErrors.length === 0) return null;
    return baseErrors.map((item) => item.title).join(", ");
  }
  if (error instanceof Error) {
    return error.message;
  }
  return null;
};

export const getFirstErrorText = (error: unknown): string | null => {
  if (error instanceof JSONApiErrorsException) {
    if (error.errors[0]?.title) {
      return error.errors[0].title;
    }
  }
  if (error instanceof Error) {
    return error.message;
  }
  return null;
};
