import styled from "styled-components";

export interface SpaceProps {
  direction?: "horizontal" | "vertical";
  children?: React.ReactNode;
}

const Space = styled.div.attrs(
  ({ direction = "vertical" }: SpaceProps) => ({ direction }),
)`
  display: flex;
  flex-direction: ${({ direction }) =>
    direction === "vertical" ? "column" : "row"};
  gap: 20px;
`;

export default Space;
