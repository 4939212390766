import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React, { useCallback, useEffect, useRef } from "react";
import ReCAPTCHABase from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import styled, { css } from "styled-components";
import * as yup from "yup";
import ReCAPTCHA from "../../../components/ReCAPTCHA";
import Alert from "../../../lib/Alert";
import { Button } from "../../../lib/Button";
import FormField from "../../../lib/FormField";
import Input from "../../../lib/Input";
import Space from "../../../lib/Space";
import Text from "../../../lib/Text";
import Title from "../../../lib/Title";
import { JSONApiErrorsException } from "../../../network/jsonApiV2/models/core/JSONApiErrorsPayload";
import {
  getBaseErrorText,
  mapJSONApiErrors,
} from "../../../utils/errors";
import { useHandleSubmitImpl } from "../../../utils/forms";
import mediaQuery from "../../../utils/mediaQuery";
import Layout from "../Layout";
import { useUpdatePassword } from "./hooks";
import imgSrc from "./signin-bg2-a73bb9ef0fc46cb003538bafd272607d.jpeg";

export interface FormProps {
  password: string;
  currentPassword: string;
  passwordConfirmation: string;
  recaptchaToken: string;
}

const ChangePasswordButton = styled(Button)`
  align-self: flex-start;
`;
const NewUserText = styled(Text)``;

const StyledInput = styled.input.attrs({ as: Input })``;
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  > ${Title} {
    font-size: 22px;
    line-height: 1.33;
    margin-bottom: 20px;
    ${mediaQuery(
      "greaterThanTablet",
      css`
        font-size: 30px;
        margin-bottom: 30px;
      `,
    )}
  }
  ${NewUserText} {
    font-size: 18px;
    margin-bottom: 50px;
    ${mediaQuery(
      "greaterThanTablet",
      css`
        font-size: 20px;
        margin-bottom: 30px;
      `,
    )}
  }
  ${ChangePasswordButton} {
    font-size: 20px;
    margin-top: 15px;
  }
  max-width: 400px;

  ${StyledInput} {
    ${mediaQuery(
      "greaterThanTablet",
      css`
        max-width: 380px;
      `,
    )}
  }
`;

const Hint = styled(Text)`
  color: #7a7a7a;
  margin-top: -14px;
`;

const Subtitle = styled(Text)`
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 30px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const minPasswordLength = 14;
const passwordRegexp =
  /^(?=.{14,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~])/;

const ChangePasswordFormSchema = yup.object().shape({
  currentPassword: yup.string().required("can't be blank"),
  password: yup
    .string()
    .required("Password is a required field")
    .min(
      minPasswordLength,
      `Password is too short (minimum is ${minPasswordLength} characters)`,
    )
    .matches(
      passwordRegexp,
      `Password should contain lower and upper case letters and at least one number (0-9), and special characters (!&*)`,
    ),
  passwordConfirmation: yup.string().required("can't be blank"),
});
const resolver = yupResolver(ChangePasswordFormSchema);

const PasswordExpiredScreenGuard: React.FC = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormProps>({
    resolver,
  });

  const {
    isLoading: isSubmitting,
    mutate,
    error: externalError,
  } = useUpdatePassword();

  useEffect(() => {
    if (externalError instanceof JSONApiErrorsException) {
      const parsedErrors = mapJSONApiErrors(externalError.errors, {
        currentPassword: "data/attributes/current_password",
        password: "data/attributes/password",
        passwordConfirmation: "data/attributes/password_confirmation",
      });

      for (const entry of Object.entries(parsedErrors)) {
        const error = entry[1];
        const key = entry[0] as keyof typeof parsedErrors;
        if (error?.title) {
          setError(key, {
            message: error.title,
          });
        }
      }
    }
  }, [setError, externalError]);

  const recaptchaRef = useRef<ReCAPTCHABase>(null);

  const onSubmit = useCallback(
    (values: FormProps & { recaptchaToken: string }) => {
      mutate(values);
    },
    [mutate],
  );

  const { handler: handleSubmitImpl, error: internalError } =
    useHandleSubmitImpl(handleSubmit, onSubmit, recaptchaRef);

  const error = externalError || internalError;
  const baseErrorText = getBaseErrorText(error);

  return (
    <>
      <Layout imgSrc={imgSrc}>
        <FormContainer>
          <Title level="h2">Update your password</Title>
          <Subtitle>
            Your security is important to us. To help protect your
            account, we require all users to update their passwords
            periodically. It&apos;s time to create a new password.
          </Subtitle>
          <StyledForm onSubmit={handleSubmitImpl}>
            <Space direction="vertical">
              {!!baseErrorText && (
                <Alert kind="text" status="error">
                  {baseErrorText}
                </Alert>
              )}
              <FormField
                error={errors.currentPassword?.message}
                label="Current password"
              >
                <StyledInput
                  autoComplete="current-password"
                  type="password"
                  {...register("currentPassword")}
                />
              </FormField>
            </Space>
            <Space direction="vertical">
              <FormField
                error={errors.password?.message}
                label="New password"
              >
                <StyledInput
                  autoComplete="new-password"
                  type="password"
                  {...register("password")}
                />
              </FormField>
              <Hint>
                Must be at least 14 characters long, contain lower
                and&nbsp;upper case letters, at least one number
                (0-9), and special characters (!&*).
              </Hint>
            </Space>
            <Space direction="vertical">
              <FormField
                error={errors.passwordConfirmation?.message}
                label="Repeat new password"
              >
                <StyledInput
                  autoComplete="new-password"
                  type="password"
                  {...register("passwordConfirmation")}
                />
              </FormField>
            </Space>
            <ReCAPTCHA ref={recaptchaRef} />
            <ChangePasswordButton
              loading={isSubmitting}
              resetWidth
              type="submit"
            >
              Change password
            </ChangePasswordButton>
          </StyledForm>
        </FormContainer>
      </Layout>
    </>
  );
};

export default PasswordExpiredScreenGuard;
