/* eslint-disable max-lines */
import {
  AriaAttributes,
  FC,
  ForwardedRef,
  InputHTMLAttributes,
  ReactNode,
  forwardRef,
  useCallback,
  useRef,
  useState,
  ComponentProps,
  CSSProperties,
} from "react";
import styled, { css } from "styled-components";
import { colors } from "../../constants/colors";
import Icon from "../Icon";

export const SuffixWrapper = styled.span`
  align-items: center;
  bottom: 0;
  display: flex;
  flex: 0;

  position: absolute;
  right: 0;
  top: 0;
  user-select: none;
`;

const commonIconStyles = css`
  cursor: pointer;
  font-size: 22px;
  padding: 7px;
`;
const Cross: FC<Omit<ComponentProps<typeof Icon>, "type">> = (
  props,
) => <Icon {...props} height="16px" type="cross" width="16px" />;
const EyeOpen: FC<Omit<ComponentProps<typeof Icon>, "type">> = (
  props,
) => <Icon {...props} type="passwordEyeOpen" />;
const EyeClosed: FC<Omit<ComponentProps<typeof Icon>, "type">> = (
  props,
) => <Icon {...props} type="passwordEyeClosed" />;
const CrossStyled = styled(Cross)`
  color: ${colors.grey};
  ${commonIconStyles}
  &:hover {
    color: ${colors.darkGrey1};
  }
`;
const EyeOpenStyled = styled(EyeOpen)`
  ${commonIconStyles}
`;
const EyeClosedStyled = styled(EyeClosed)`
  ${commonIconStyles}
`;

/**
 * Ordinary input with some predefined styles.
 */
const InputRaw = styled.input<InputExtraProps>`
  background: transparent;
  border: none;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  outline: none;

  &::placeholder {
    color: ${colors.grey};
    opacity: 1; /* Firefox */
  }

  /* Hide clear button if the input is empty. Apply condition only if placeholder is set, because the check relies on it.*/
  ${({ placeholder }) =>
    placeholder
      ? css`
          &:placeholder-shown {
            & ~ ${SuffixWrapper} {
              ${CrossStyled} {
                display: none;
              }
            }
          }
        `
      : undefined};

  &[disabled] {
    color: ${colors.grey};
    cursor: not-allowed;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${colors.grey};
      opacity: 1; /* Firefox */
    }
  }
`;

const disabledInputContainerStyle = css`
  background: ${colors.lightGrey2};
  /* border: 1px solid ${colors.lightGrey1}; */
  border-radius: 8px;
  color: ${colors.grey};
  cursor: not-allowed;
`;

interface InputExtraProps
  extends Pick<AriaAttributes, "aria-invalid">,
    Pick<
      InputHTMLAttributes<HTMLInputElement>,
      "disabled" | "type"
    > {}

export interface InputProps
  extends InputExtraProps,
    InputHTMLAttributes<HTMLInputElement> {
  /**
   * Either the input has validation error.
   * @see: https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_aria-invalid_attribute
   */
  "aria-invalid"?:
    | boolean
    | "false"
    | "grammar"
    | "spelling"
    | "true"
    | undefined;
  /**
   * The suffix icon for the Input
   */
  suffix?: ReactNode;

  /**
   * If allow to remove input content with clear icon
   */
  allowClear?: boolean;

  ref?: ForwardedRef<HTMLInputElement>;

  className?: string;

  style?: CSSProperties;
}

interface AffixWrapperProps extends InputExtraProps {
  hasSuffix: boolean;
}

const Border = styled.div`
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
`;

export const AffixWrapper = styled.span.withConfig<AffixWrapperProps>(
  {
    shouldForwardProp: (prop, defaultValidatorFn) =>
      // styled-components is a dumb thing and does not filter correctly https://github.com/emotion-js/emotion/blob/main/packages/is-prop-valid/src/props.js
      !["disabled", "type"].includes(prop) &&
      defaultValidatorFn(prop),
  },
)`
  display: inline-flex;
  background: ${colors.white};
  border-radius: 8px;
  box-sizing: border-box;
  color: ${colors.darkGrey1};
  height: 50px;
  position: relative;

  ${InputRaw} {
    padding: 0 ${({ hasSuffix }) => (hasSuffix ? 0 : "18px")} 0 18px;
  }

  ${SuffixWrapper} {
    margin: 0 7px 0 0;
  }

  ${InputRaw} {
    flex: 1;
  }

  ${Border} {
    border: ${({ "aria-invalid": hasError }) =>
      hasError
        ? css`2px solid ${colors.error}`
        : css`1px solid ${colors.lightGrey1}`};
    border-radius: 8px;
  }

  &:hover {
    ${Border} {
      ${({ disabled }) =>
        disabled
          ? undefined
          : css`
              border-color: ${colors.darkGrey2};
            `}
    }
  }

  &:focus-within {
    ${Border} {
      border: 2px solid ${colors.primary};
    }
  }
  ${({ disabled }) =>
    disabled ? disabledInputContainerStyle : undefined}
`;

const Input: FC<InputProps> = forwardRef<
  HTMLInputElement,
  InputProps
>(
  (
    { suffix, allowClear, onChange, className, style, ...restProps },
    ref,
  ) => {
    const isPasswordField = restProps.type === "password";
    const [showPassword, setShowPassword] = useState(false);

    const showPasswordIcon = (
      <EyeOpenStyled onClick={() => setShowPassword(true)} />
    );
    const hidePasswordIcon = (
      <EyeClosedStyled onClick={() => setShowPassword(false)} />
    );
    const myOwnRef = useRef<HTMLInputElement | null>(null);

    const clearIcon = (
      <CrossStyled
        onClick={() => {
          if (myOwnRef.current) {
            myOwnRef.current.value = "";
            // @ts-expect-error @see: https://github.com/facebook/react/issues/11488
            // eslint-disable-next-line no-underscore-dangle,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            myOwnRef.current._valueTracker?.setValue(undefined);
            // manually trigger the input event to tell React to trigger onChange with a proper event type
            myOwnRef.current.dispatchEvent(
              new Event("input", { bubbles: true }),
            );
          }
        }}
      />
    );
    let patchedSuffix: ReactNode;

    if (isPasswordField) {
      patchedSuffix = showPassword
        ? hidePasswordIcon
        : showPasswordIcon;
    } else if (allowClear) {
      patchedSuffix = clearIcon;
    } else {
      patchedSuffix = suffix;
    }

    const maintainRef = useCallback(
      (element: HTMLInputElement | null) => {
        myOwnRef.current = element;
        if (ref) {
          if (typeof ref === "function") {
            ref(element);
          } else {
            // eslint-disable-next-line no-param-reassign
            ref.current = element;
          }
        }
      },
      [ref],
    );

    return (
      <AffixWrapper
        aria-invalid={restProps["aria-invalid"]}
        className={className}
        disabled={restProps.disabled}
        hasSuffix={!!patchedSuffix}
        style={style}
        type={restProps.type}
      >
        <InputRaw
          {...restProps}
          ref={maintainRef}
          onChange={onChange}
          type={
            isPasswordField && showPassword ? "text" : restProps.type
          }
        />
        {patchedSuffix && (
          <SuffixWrapper>{patchedSuffix}</SuffixWrapper>
        )}
        <Border />
      </AffixWrapper>
    );
  },
);

export default Input;
