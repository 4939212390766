import { setUser as sentrySetUser } from "@sentry/react";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { isLeft, isRight } from "fp-ts/Either";
import * as t from "io-ts";
import { useAxios } from "../../network";
import { createResourceIdentifierC } from "../../network/jsonApiV3/builder";

const ProviderC = t.type({
  attributes: t.type({
    name: t.string,
    npi: t.string,
    pharmacy: t.boolean,
    uid: t.string,
  }),
  id: t.string,
  type: t.literal("provider"),
});

const userAttributesC = t.type({
  email: t.string,
  fname: t.string,
  lname: t.string,
  name: t.string,
  password_is_expired: t.boolean,
  title: t.string,
});

const responseC = t.type({
  data: t.type({
    attributes: userAttributesC,
    id: t.string,
    relationships: t.type(
      {
        providers: t.type({
          data: t.array(createResourceIdentifierC("provider")),
        }),
      },
      "relationships",
    ),
    type: t.literal("user"),
  }),
  included: t.array(ProviderC),
});

const tokenC = t.type(
  {
    meta: t.type(
      {
        csrf_token: t.string,
      },
      "csrf_token",
    ),
  },
  "meta",
);

export type SessionUser = {
  id: string;
  type: "user";
  attributes: t.TypeOf<typeof userAttributesC>;
  providers: Array<t.TypeOf<typeof ProviderC>>;
};

export interface Session {
  user: SessionUser | null;
  csrfToken: string | null;
}

export const transformResponse = (
  response: AxiosResponse,
): Session => {
  let csrfToken: string | null = null;
  const metaDecodeResult = tokenC.decode(response.data);
  csrfToken = isRight(metaDecodeResult)
    ? metaDecodeResult.right.meta.csrf_token
    : null;
  if (isLeft(metaDecodeResult)) {
    // eslint-disable-next-line no-console
    console.error(metaDecodeResult.left);
  }

  if (response.status === 200) {
    const decodeResult = responseC.decode(response.data);

    if (isRight(decodeResult)) {
      const jsonApiData = decodeResult.right;
      const { data, included } = jsonApiData;
      const { attributes, relationships, id } = data;

      const providersIds = relationships.providers.data
        .filter((item) => item.type === "provider")
        .map((item) => item.id);
      const providers = included.filter(
        (item) =>
          item.type === "provider" && providersIds.includes(item.id),
      );

      return {
        csrfToken,
        user: {
          attributes,
          id,
          providers,
          type: "user",
        },
      };
    }
    // eslint-disable-next-line no-console
    console.log(decodeResult.left);
    throw new Error("Invalid response format");
  } else {
    return {
      csrfToken,
      user: null,
    };
  }
};

/**
 * @note: exception in this method creates infinity sign-in/sign-out loop, so it must be wrapped in try/catch
 */

export default function useAuthSession(): UseQueryResult<Session> {
  const axios = useAxios();
  return useQuery(
    ["cookie"],
    async () => {
      let sentryUserId: string | undefined;
      try {
        const response = await axios.get("/v1/session?include=user", {
          validateStatus: (status) => status < 500,
        });
        const result = transformResponse(response);
        sentryUserId = result.user?.id;
        return result;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        return {
          csrfToken: null,
          user: null,
        };
      } finally {
        sentrySetUser(
          {
            id: sentryUserId,
          } || null,
        );
      }
    },
    {
      cacheTime: Number.POSITIVE_INFINITY,
      staleTime: Number.POSITIVE_INFINITY,
    },
  );
}
