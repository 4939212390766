import {
  BaseSyntheticEvent,
  RefObject,
  useCallback,
  useMemo,
  useState,
} from "react";
import ReCAPTCHABase from "react-google-recaptcha";
import { UseFormHandleSubmit } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { SubmitHandler } from "react-hook-form/dist/types/form";

export type PreSubmitValidator<FormProps extends FieldValues> = (
  values: FormProps,
) => Promise<boolean>;
export function useHandleSubmitImpl<FormProps extends FieldValues>(
  handleSubmit: UseFormHandleSubmit<FormProps>,
  onSubmit: SubmitHandler<FormProps>,
): {
  handler: (event?: BaseSyntheticEvent) => void;
  error: unknown | undefined;
};
export function useHandleSubmitImpl<FormProps extends FieldValues>(
  handleSubmit: UseFormHandleSubmit<FormProps>,
  onSubmit: SubmitHandler<FormProps & { recaptchaToken: string }>,
  recaptchaRef: RefObject<ReCAPTCHABase>,
): {
  handler: (event?: BaseSyntheticEvent) => void;
  error: unknown | undefined;
};
export function useHandleSubmitImpl<FormProps extends FieldValues>(
  handleSubmit: UseFormHandleSubmit<FormProps>,
  onSubmit: SubmitHandler<FormProps & { recaptchaToken: string }>,
  recaptchaRef: RefObject<ReCAPTCHABase>,
  /**
   * It is called after passing ReCAPTCHA verification but before submitting the form
   *
   * Can be used as an additional validation, e.g. to check if email exists
   *
   * Use it carefully, it is kind of hack
   * @returns{Promise<boolean>} - if true - submit is allowed, otherwise - do not call "onSubmit"
   */
  preSubmitValidation: PreSubmitValidator<FormProps>,
): {
  handler: (event?: BaseSyntheticEvent) => void;
  error: unknown | undefined;
};
export function useHandleSubmitImpl<FormProps extends FieldValues>(
  handleSubmit: UseFormHandleSubmit<FormProps>,
  onSubmit: SubmitHandler<FormProps & { recaptchaToken?: string }>,
  recaptchaRef?: RefObject<ReCAPTCHABase>,
  preSubmitValidation?: PreSubmitValidator<FormProps>,
): {
  handler: (event?: BaseSyntheticEvent) => void;
  error: unknown | undefined;
} {
  const [error, setError] = useState<unknown>();
  const handler = useCallback(
    (event?: BaseSyntheticEvent) => {
      handleSubmit(async (values) => {
        setError(undefined);
        if (recaptchaRef) {
          const recaptcha = recaptchaRef.current;
          if (!recaptcha) {
            // eslint-disable-next-line no-console
            console.error(
              "ReCAPTCHA ref is presented, but value is null",
            );
            return undefined;
          }

          // if there is a value - then it is a second attempt and there is a bug https://github.com/dozoisch/react-google-recaptcha/issues/191
          if (recaptcha.getValue()) {
            recaptcha.reset();
          }

          const recaptchaToken = await recaptcha.executeAsync();
          if (recaptchaToken) {
            let isSubmitAllowed = true;
            if (preSubmitValidation) {
              isSubmitAllowed = await preSubmitValidation(values);
            }
            if (isSubmitAllowed) {
              onSubmit({
                ...values,
                recaptchaToken,
              });
            }
          } else {
            throw new Error("Unknown error. Please reload the page");
          }
        } else {
          onSubmit(values);
        }
        return undefined;
      })(event).catch((err) => {
        if (err) {
          setError(err);
        } else {
          setError(
            new Error("Unknown error. Please reload the page"),
          );
        }
      });
    },
    [handleSubmit, onSubmit, preSubmitValidation, recaptchaRef],
  );

  return useMemo(() => ({ error, handler }), [error, handler]);
}
