import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { useAxios } from "../../../network";

export function useUpdatePassword(): UseMutationResult<
  unknown,
  unknown,
  {
    currentPassword: string;
    password: string;
    passwordConfirmation: string;
    recaptchaToken: string;
  }
> {
  const axios = useAxios();
  const queryClient = useQueryClient();
  return useMutation(
    async ({
      currentPassword,
      password,
      passwordConfirmation,
      recaptchaToken,
    }) => {
      await axios.put(`/v1/password/change`, {
        current_password: currentPassword,
        "g-recaptcha-response": recaptchaToken,
        password,
        password_confirmation: passwordConfirmation,
      });
    },
    {
      onSuccess: async () => {
        await queryClient.refetchQueries(["cookie"]);
      },
    },
  );
}
