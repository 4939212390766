import { CSSProperties } from "react";
import styled from "styled-components";
import { colors } from "../../constants/colors";

export type TextType =
  | "default"
  | "medium"
  | "small"
  | "table"
  | "tableMedium";

export interface ExtraProps {
  textType?: TextType;
}

type DefaultExtraProps = Required<Pick<ExtraProps, "textType">>;
const defaultProps: DefaultExtraProps = {
  textType: "default",
};

const FONT_SIZE: Record<
  TextType,
  NonNullable<CSSProperties["fontSize"]>
> = {
  default: "16px",
  medium: "16px",
  small: "13px",
  table: "15px",
  tableMedium: "15px",
};
const LINE_HEIGHT: Record<
  TextType,
  NonNullable<CSSProperties["lineHeight"]>
> = {
  default: "20px",
  medium: "20px",
  small: "16px",
  table: "20px",
  tableMedium: "20px",
};
const Text = styled.span.attrs(
  (props: DefaultExtraProps & ExtraProps) => props,
)<ExtraProps>`
  color: ${colors.black};
  font-size: ${({ textType }) => FONT_SIZE[textType]};
  font-weight: ${({ textType }) =>
    textType === "medium" || textType === "tableMedium"
      ? 500
      : "normal"};
  line-height: ${({ textType }) => LINE_HEIGHT[textType]};
`;

Text.defaultProps = defaultProps;
export default Text;
