import Cookies from "js-cookie";
import { FC, ForwardedRef, forwardRef, useMemo } from "react";
import ReCAPTCHABase, {
  ReCAPTCHAProps as ReCAPTCHABaseProps,
} from "react-google-recaptcha";
import ReCAPTCHADisabled from "./ReCAPTCHADisabled";

export type ReCAPTCHAExternalProps = Omit<
  ReCAPTCHABaseProps,
  "sitekey"
>;
export interface ReCAPTCHAProps extends ReCAPTCHAExternalProps {
  forwardedRef?: ForwardedRef<ReCAPTCHABase>;
}

const ReCAPTCHABound: FC<ReCAPTCHAProps> = (props) => {
  const { forwardedRef, size = "invisible", ...restProps } = props;
  const sitekey = import.meta.env.VITE_RECAPTCHA_SITEKEY;
  if (typeof sitekey !== "string") {
    throw new Error("VITE_RECAPTCHA_SITEKEY is not defined");
  }
  const isDisabled = useMemo(() => {
    const disabled =
      !!Cookies.get("_skip_recaptcha") ||
      import.meta.env.VITE_RECAPTCHA_DISABLED === "true";

    if (disabled) {
      // eslint-disable-next-line no-console
      console.warn("ReCAPTCHA disabled by a cookie");
    }
    return disabled;
  }, []);

  if (isDisabled) {
    return <ReCAPTCHADisabled ref={forwardedRef as never} />;
  }
  return (
    <ReCAPTCHABase
      {...restProps}
      ref={forwardedRef}
      sitekey={sitekey}
      size={size}
    />
  );
};

const ReCAPTCHA = forwardRef(
  (
    props: ReCAPTCHAExternalProps,
    ref: ForwardedRef<ReCAPTCHABase>,
  ) => {
    return <ReCAPTCHABound {...props} forwardedRef={ref} />;
  },
);

export default ReCAPTCHA;
