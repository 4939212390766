import React from "react";
import styled, { css } from "styled-components";
import Logo from "../../../lib/Logo";
import mediaQuery from "../../../utils/mediaQuery";

export interface LayoutProps {
  children?: React.ReactNode;
  imgSrc: string;
}

const Container = styled.div`
  background-color: white;
  display: grid;
  grid-template-areas: "content";
  grid-template-columns: 1fr;
  min-height: 100%;

  ${mediaQuery(
    "greaterThanTablet",
    css`
      grid-template-areas: "content backgroundImage";
      grid-template-columns: 1fr 1fr;
    `,
  )}
`;

export const LogoContainer = styled.div`
  grid-area: logo;
  margin-bottom: 80px;
  ${mediaQuery(
    "greaterThanTablet",
    css`
      margin-bottom: 70px;
    `,
  )}
`;
const ContentContainer = styled.div`
  display: grid;
  grid-template-areas:
    "logo"
    "children";
  grid-template-rows: min-content 1fr;
  padding: 20px;
  ${mediaQuery(
    "greaterThanTablet",
    css`
      padding: 70px 135px;
    `,
  )}
`;
const ChildrenContainer = styled.div`
  grid-area: children;
`;
const BackgroundImage = styled.div<{ $src: string }>`
  background-image: url(${({ $src }) => $src});
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  grid-area: backgroundImage;
`;

const Layout: React.FC<LayoutProps> = (props) => {
  const { children, imgSrc } = props;

  return (
    <>
      <Container>
        <ContentContainer>
          <LogoContainer>
            <Logo />
          </LogoContainer>
          <ChildrenContainer>{children}</ChildrenContainer>
        </ContentContainer>
        <BackgroundImage $src={imgSrc} />
      </Container>
    </>
  );
};

export default Layout;
