/* eslint-disable react/destructuring-assignment */
import React, {
  FC,
  ReactElement,
  cloneElement,
  AriaAttributes,
  ReactNode,
} from "react";
import styled from "styled-components";
import { colors } from "../../constants/colors";
import FormFieldLabel from "./FormFieldLabel";

interface ChildrenProps extends Pick<AriaAttributes, "aria-invalid"> {
  "aria-invalid"?: boolean;
  disabled?: boolean;
}
export interface CommonFormFieldProps {
  className?: string;
  label?: string;
  disabled?: boolean;
  error?: string;
}

export type FormFieldProps = CommonFormFieldProps &
  (
    | {
        /**
         * @deprecated Use `render` instead
         */
        children: ReactElement<ChildrenProps>;
        render?: undefined;
      }
    | {
        /**
         * @deprecated Use `render` instead
         */
        children?: undefined;
        /**
         * @note: Has priority on `children`
         */
        render: (props: ChildrenProps) => ReactNode;
      }
  );

export const LabelWrapper = styled.span``;
const FormFieldErrorText = styled.div`
  color: ${colors.error};
  font-size: 13px;
  font-weight: normal;
  line-height: 16px;
`;

const FormFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${FormFieldLabel} {
    display: flex;
    flex-direction: column;
    &:focus-within {
      color: ${colors.primary};
    }
  }
  ${LabelWrapper} {
    margin-bottom: 12px;
  }
  ${FormFieldErrorText} {
    margin-top: 4px;
  }
`;

const FormField: FC<FormFieldProps> = (props: FormFieldProps) => {
  const {
    label,
    error,
    disabled,
    className,
    children,
    render,
    ...restProps
  } = props;

  const childrenPatched = render
    ? render({
        "aria-invalid": !!error,
        disabled,
      })
    : cloneElement<ChildrenProps>(props.children, {
        "aria-invalid": error
          ? true
          : props.children.props["aria-invalid"],
        disabled: disabled ? true : props.children.props.disabled,
      });

  return (
    <FormFieldContainer className={className} {...restProps}>
      {label && (
        <FormFieldLabel
          themeState={
            // eslint-disable-next-line no-nested-ternary
            disabled ? "disabled" : error ? "error" : "default"
          }
        >
          <LabelWrapper>{label}</LabelWrapper>
          {childrenPatched}
        </FormFieldLabel>
      )}
      {!label && childrenPatched}
      {error && (
        <FormFieldErrorText role="alert">{error}</FormFieldErrorText>
      )}
    </FormFieldContainer>
  );
};

export default FormField;
