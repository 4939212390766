import { CSSProperties, FC } from "react";
import styled from "styled-components";
import { colors } from "../../constants/colors";
import { AlertKind, AlertStatus } from "./types";

export interface AlertProps {
  className?: string;
  kind: AlertKind;
  status: AlertStatus;
  title?: string;
  children: React.ReactNode;
}

const AlertTitle = styled.div`
  font-size: 24px;
`;

const alertColors: Record<AlertStatus, CSSProperties["color"]> = {
  error: colors.error,
  info: colors.darkGrey1,
  success: colors.success,
};
const AlertContainer = styled.div<{
  kind: AlertKind;
  status: AlertStatus;
}>`
  background: none;
  border: none;
  color: ${({ status }) => alertColors[status]};
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;

  ${AlertTitle} {
    margin-bottom: 4px;
  }
`;

const Alert: FC<AlertProps> = (props) => {
  const { className, children, kind, status, title } = props;

  return (
    <AlertContainer
      className={className}
      kind={kind}
      role="alert"
      status={status}
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {children}
    </AlertContainer>
  );
};

export default Alert;
