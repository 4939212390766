import { FC } from "react";
import { RouterProvider } from "react-router-dom";
import useAuthSession from "./authentication/hooks/useAuthSession";
import SessionTimer from "./components/SessionTimer";
import MessagesContainer from "./lib/messages/MessagesContainer";
import MessagesHost from "./lib/messages/MessagesHost";
import Spin from "./lib/Spin";
import {
  authenticatedRoutes,
  notAuthenticatedRoutes,
} from "./router";

const Loader = () => <Spin mode="fullscreen" />;

const App: FC = () => {
  const { data: authSession, isLoading: isAuthSessionLoading } =
    useAuthSession();

  if (isAuthSessionLoading) {
    return <Loader />;
  }

  return (
    <>
      <MessagesHost>
        {authSession && authSession.user ? (
          <RouterProvider
            router={authenticatedRoutes(authSession.user)}
          />
        ) : (
          <RouterProvider router={notAuthenticatedRoutes} />
        )}
        {authSession && authSession.user && <SessionTimer />}
        <MessagesContainer />
      </MessagesHost>
    </>
  );
};

export default App;
