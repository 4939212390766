import { CSSProperties } from "react";
import styled from "styled-components";
import { colors } from "../../../constants/colors";

export type FormFieldLabelThemeState =
  | "default"
  | "disabled"
  | "error";

export interface FormFieldLabelProps {
  themeState: FormFieldLabelThemeState;
}

const LABEL_COLORS: Record<
  FormFieldLabelThemeState,
  NonNullable<CSSProperties["color"]>
> = {
  default: colors.darkGrey1,
  disabled: colors.grey,
  error: colors.darkGrey1,
};
const FormFieldLabel = styled.label<FormFieldLabelProps>`
  color: ${({ themeState }) => LABEL_COLORS[themeState]};
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
`;

export default FormFieldLabel;
